import React, { useContext, useState, useEffect } from 'react';
import '../css/escalationcontactsmodal.css';

const EscalationContactsModal = ({ onClose, handleFieldChange, handleCheckboxChange, escalationSaveAndRefresh, selectedTitle, escalationContact, setEscalationContact, phoneNumberValid, setPhoneNumberValid, validatePhoneNumber, formatPhoneNumber }) => {
    const handlePhoneNumberChange = (e) => {
        const { value } = e.target;
        handleFieldChange(e);
    };

    useEffect(() => {
        const phoneNumberChangeEvent = {
            target: {
                name: 'escalation_phone_number',
                value: escalationContact?.escalation_phone_number
            }
        };
        if (escalationContact) {
            handleFieldChange(phoneNumberChangeEvent); // Trigger the field change event for the phone number field on component mount
        }
    }, []);

    return (
        <div className="modal">
            <div className="modal-content">
                {escalationContact?.escalation_id ? (
                <h2>Edit Escalation Contact</h2>
                    ) : (
                <h2>Add Escalation Contact</h2>
                    )}

                <label>
                    Title:

                    <select
                        name="escalation_title"
                        value={escalationContact?.escalation_title}
                        onChange={handleFieldChange}
                        required
                    >
                        <option value="">Select Title</option>
                        <option value="Manager">Manager</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Team Leader">Team Leader</option>
                        <option value="Support Specialist">Support Specialist</option>
                        <option value="Technical Lead">Technical Lead</option>
                        <option value="Executive">Executive</option>
                        <option value="Director">Director</option>
                        <option value="Coordinator">Coordinator</option>
                        <option value="Officer">Officer</option>
                        <option value="Analyst">Analyst</option>
                        <option value="Administrator">Administrator</option>
                        <option value="Other">Other</option>
                    </select>
                    {selectedTitle === "Other" && (
                        <input
                        type="text"
                        name="customTitle"
                        value={escalationContact?.customTitle}
                        onChange={handleFieldChange}
                        placeholder="Enter a title of your choice..."
                        />
                    )}
                </label>

                <label>
                    First Name:
                    <input
                        type="text"
                        name="escalation_first_name"
                        value={escalationContact?.escalation_first_name}
                        onChange={handleFieldChange}
                        required
                    />
                </label>

                <label>
                    Last Name:
                    <input
                        type="text"
                        name="escalation_last_name"
                        value={escalationContact?.escalation_last_name}
                        onChange={handleFieldChange}
                        required
                    />
                </label>
            
                <label>
                    Email:
                    <input
                        type="email"
                        name="escalation_email"
                        value={escalationContact?.escalation_email}
                        onChange={handleFieldChange}
                        required
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="escalation_phone_number"
                        value={escalationContact?.escalation_phone_number}
                        onChange={handlePhoneNumberChange}
                        required
                    />
                    {!phoneNumberValid && <span className="error-message">Phone number be in the given format: +1 (888)888-9999</span>}
                </label>
                <label>
                    Accepts Text Messages:
                    <input
                        type="checkbox"
                        name="accepts_text_message"
                        checked={escalationContact.accepts_text_message === '1'}
                        onChange={handleCheckboxChange}
                    />
                </label>

                <div className="modal-buttons">
                    <button type="button" className='newBtn' onClick={escalationSaveAndRefresh}>Save</button>
                    <button type="button" className='newBtn' onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default EscalationContactsModal;