import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import Modal from 'react-modal';
import axios from 'axios';
import ListGroupContacts from './ListGroupContacts';
import '../css/contactgroups.css';


const CreateGroup = ({ isOpen, closeModal }) => {
    const { apiUrl, hmac, setReloadData } = useContext(AppContext);
    const [groupId] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'groupName') {
            setGroupName(value);
        } else if (name === 'groupDescription') {
            setGroupDescription(value);
        }
    };  

    const handleSubmit = async (e) => {
        e.preventDefault();

        await addGroup();
        // Here you can perform any necessary actions, such as sending the form data to the API to create a new group
        // You can access the groupName and groupDescription values here and perform any validation or processing before submitting
        
        // Reset the form fields to their initial values
        setGroupName('');
        setGroupDescription('');
        setReloadData(true);

        // Close the modal
        closeModal();
    };

    const addGroup = async () => {   

        let formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'addNewGroup');
        formData.append('group_name', groupName);
        formData.append('group_description', groupDescription);            
   
        try {
            const response = axios.post(apiUrl, formData);
            setReloadData(true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal}>
            <div className='modalContainer'>
                <button className="Modal-close" type="button" onClick={closeModal}>&times;</button>
                <h2>Create a Contact Group</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="groupName">Group Name</label>
                        <input type="text" id="groupName" name="groupName" defaultValue={groupName} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label htmlFor="groupDescription">Group Description</label>
                        <textarea id="groupDescription" name="groupDescription" defaultValue={groupDescription} onChange={handleInputChange} />
                    </div>
                    <button type="submit">Create A Contact Group</button>
                </form>
            </div>
        </Modal>
    );
};

const EditGroup = ({ group, isOpen, closeModal }) => {
    const [groupId, setGroupId] = useState(group.contact_group_id);
    const [groupName, setGroupName] = useState(group.contact_group_name);
    const [groupDescription, setGroupDescription] = useState(group.contact_group_description);
    const { apiUrl, hmac, setReloadData } = useContext(AppContext);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'groupName') {
            setGroupName(value);
        } else if (name === 'groupDescription') {
            setGroupDescription(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        

        // Here you can perform any necessary actions, such as sending the form data to the API to update the group
        await addEditGroup(); //API Call

        // Reset the form fields to their initial values
        setGroupId('');
        setGroupName('');
        setGroupDescription('');

        // Close the modal
        closeModal();
    };

    const addEditGroup = async () => {   
        let formData = new FormData();
        if (groupId) {
            formData.append('userHmacId', hmac);
            formData.append('action', 'updateGroup');
            formData.append('group_id', groupId);
            formData.append('group_name', groupName);
            formData.append('group_description', groupDescription);
        }

        try {
            const response = axios.post(apiUrl, formData);
            setReloadData(true);
        } catch (error) {
            console.log(error);
        }
    }    

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal}>
            <div className='modalContainer'>
                <button className="Modal-close" type="button" onClick={closeModal}>&times;</button>
                <h2>Edit Group</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="groupName">Group Name</label>
                        <input type="text" id="groupName" name="groupName" defaultValue={groupName} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label htmlFor="groupDescription">Group Description</label>
                        {/* <textarea id="groupDescription" name="groupDescription" defaultValue={groupDescription} onChange={handleInputChange} /> */}
                        <textarea id="groupDescription" name="groupDescription" onChange={handleInputChange} value={groupDescription}></textarea>
                    </div>
                    <button type="submit">Update Group</button>
                </form>
            </div>
        </Modal>
    );
};

const ListGroups = ({openEditModal}) => {
    const [groupData, setGroupData] = useState([]);
    const { apiUrl, hmac, reloadData, setReloadData } = useContext(AppContext);
    const [selectedGroupId, setSelectedGroupId] = useState(null); // New state for selected group ID
    const [currentPage, setCurrentPage] = useState();

    
    useEffect(() => {
        const fetchData = async () => {
            try {

                let formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'listGroups');
                const response = await axios.post(apiUrl, formData);
                if (response.data.status == 'success') {
                    const response_data = response.data.data;
                    setGroupData(response_data);
                } else {
                    setGroupData([]);
                }
                setReloadData(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [reloadData]);

    const deleteGroup = async (group) => {
        let groupId = group.contact_group_id;
        let formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'deleteGroup');
        formData.append('group_id', groupId);
        const response = await axios.post(apiUrl, formData);
        setReloadData(true);
    }

    return (
        <div>
            <div className="contact_group">
                {groupData?.length > 0 && groupData.map((group) => (
                    <div className="group_item" key={group.contact_group_id}>
                        <h2>{group.contact_group_name}</h2>
                        <p>{group.contact_group_description}</p>
                        <button className='newBtn' onClick={() => {
                            setCurrentPage(1);
                            setSelectedGroupId(group.contact_group_id)
                        }}>
                            Manage Contacts
                        </button>
                        <button className='newBtn' onClick={() => {
                            openEditModal(group);
                        }}>
                            edit
                        </button>
                        <button className='newBtn' onClick={() => {
                            const confirmDelete = window.confirm("Are you sure you want to delete this contact group?");
                            if (confirmDelete) {
                                deleteGroup(group);
                            }
                        }}>
                            delete
                        </button>
                    </div>
                ))}
            </div>
            {selectedGroupId && <ListGroupContacts currentPage={currentPage} setCurrentPage={setCurrentPage} groupId={selectedGroupId} />}
        </div>
    )
}

export { CreateGroup, EditGroup, ListGroups};