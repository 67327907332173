import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/astrotext.css';

const Astrovoice = () => {
    const { setNewView } = useContext(AppContext);

    useEffect(() => {
        // fetchConversationsFromAPI();
    }, []);



    return (
        <div className='content_container'>
            <div className='astrotextOptions'>
                <button onClick={() => {setNewView('Communications')}}>Assistant Settings (Incoming Calls)</button>
                <button onClick={() => {setNewView('OutgoingCalls');}}>Outgoing Calls</button>
            </div>
        </div>
    );
};

export default Astrovoice;