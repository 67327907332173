import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/astrotext.css';

const Astrotext = () => {
    const { setNewView } = useContext(AppContext);

    useEffect(() => {
        // fetchConversationsFromAPI();
    }, []);



    return (
        <div className='content_container'>
            <div className='astrotextOptions'>
                <button onClick={() => {setNewView('Campaigns')}}>Campaigns</button>
                {/* <button onClick={() => {setNewView('Incoming')}}>Message History</button> */}
            </div>
        </div>
    );
};

export default Astrotext;