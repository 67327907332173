import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import Modal from 'react-modal';
import axios from 'axios';
import '../css/messages.css';
import Countdown from './Countdown';
import { Tooltip } from 'react-tooltip';


  
const Messages = ({ campaign, setShowMessages }) => {
    const { apiServer, apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [messages, setMessages] = useState([]);
    const [isEditing, setIsEditing] = useState([]);
    const [IsWorkingWithMessage, setIsWorkingWithMessage] = useState(false);
    const [messageData, setMessageData] = useState(0);
    const [newMessage, setNewMessage] = useState({});
    const [campaignStartDate, setCampaignStartDate] = useState();
    const [campaignEndDate, setCampaignEndDate] = useState();
    const [messagesModalVisible, setMessagesModalVisible] = useState(false);
       
    useEffect(() => {
        fetchMessagesFromAPI()
            .then(apiMessages => {setMessages(apiMessages)})
            .catch(error => console.log(error));
    }, []);

    const handleGenerateAI = async () => {
        setLoading(true);
        await fetchAIMessages();
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));
    };

    const fetchAIMessages = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'generateAIMessages');
            formData.append('campaignId', campaign.campaign_id);
            const response = await axios.post(apiUrl, formData);
            setLoading(false);
        } catch (error) {
        } finally {
            setLoading(false);
        } 
    }    


    const fetchMessagesFromAPI = async () => {
        let formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'listMessages');
        formData.append('campaignId', campaign.campaign_id);
        const response = await axios.post(apiUrl, formData);
        return response.data.data;
    }

    function convertToLocaleTime(utcDateTime) {
        const utcDate = new Date(utcDateTime);
        const year = utcDate.getFullYear();
        const month = String(utcDate.getMonth() + 1).padStart(2, '0');
        const day = String(utcDate.getDate()).padStart(2, '0');
        const hours = String(utcDate.getHours()).padStart(2, '0');
        const minutes = String(utcDate.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const authorizeMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', campaign?.user_id);
        formData.append('action', 'authorizeMessage');
        formData.append('messageId', messageId);
        formData.append('campaignId', campaign?.campaign_id);
      
        const response = await axios.post(apiUrl, formData);
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));
        
      
        // Check the response status and log if successful
        if (response.status === 200) {
        //   console.log(`Message with ID ${messageId} authorized.`);
        }
    }
      
    const deauthorizeMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', campaign?.user_id);
        formData.append('action', 'deauthorizeMessage');
        formData.append('messageId', messageId);
        formData.append('campaignId', campaign?.campaign_id);
      
        const response = await axios.post(apiUrl, formData);
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));        
      
        // Check the response status and log if successful
        if (response.status === 200) {
        }
    }

    const prefaceEditMessage = (message) => {
        setIsWorkingWithMessage(true);
        setMessageData(message);
        setIsEditing(true);        
    }

    const deleteMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', campaign?.user_id);
        formData.append('action', 'deleteMessage');
        formData.append('messageId', messageId);    
        formData.append('campaignId', campaign?.campaign_id);

        const response = await axios.post(apiUrl, formData);

        // Check the response status and update the messages state if successful
        if (response.status === 200) {
            const updatedMessages = await fetchMessagesFromAPI();
            setMessages(updatedMessages);
        }
    }

    const deleteMessageMedia = async (messageMediaId) => {
        const formData = new FormData();
        formData.append('action', 'deleteMessageMedia');
        formData.append('userHmacId', campaign?.user_id);
        formData.append('campaignId', campaign?.campaign_id);
        formData.append('messageMediaId', messageMediaId);    

        const response = await axios.post(apiUrl, formData);

        // Check the response status and update the messages state if successful
        if (response.status === 200) {
            alert('left off here');

            const updatedMessages = await fetchMessagesFromAPI();
            setMessages(updatedMessages);
            setMessageData(updatedMessages);
        }
    }    


    const handleCreateNew = () => {
        setIsWorkingWithMessage(true);
        setIsEditing(false);
    }

    const handleChange = (e) => {
        setNewMessage({
            ...newMessage,
            [e.target.name]: e.target.value,
        });
    }

    const handleEditChange = (e) => {
        setMessageData({
            ...messageData,
            [e.target.name]: e.target.value
        });
    }

    const handleCloseMessages = (e) => {
        setMessagesModalVisible(false);
        setIsWorkingWithMessage(false);
        setIsEditing(false);
    }

    const handleImageUpload = (e, isCreateMessage) => {
        if (isCreateMessage) {
            setNewMessage({
                ...newMessage,
                media: e.target.files[0]
            });
        }
        else {
            setMessageData({
                ...messageData,
                media: e.target.files[0]
            });
        }
    }

    const handleSaveMsg = async (e, messageData, isCreate) => {      
        e.preventDefault();
        setLoading(true);
        let msgAction = 'createMessage';

        try {
            if (!isCreate) {
                msgAction = 'updateMessage';
            }

            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', msgAction);
            if (!isCreate) {
                formData.append('messageId', messageData?.msg_id);    
            }
            formData.append('campaignId', campaign?.campaign_id);
            formData.append('messageTitle', messageData?.message_title);
            formData.append('message', messageData?.message);
            if (messageData?.media) {
                formData.append('messageMedia', messageData?.media);
            }

            formData.append('messageSendDate', messageData?.message_send_UTC);
    
            // Send the form data using axios post request
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            // Check the response status and update the messages state if successful
            if (response.status === 200) {
                const updatedMessages = await fetchMessagesFromAPI();
                setMessages(updatedMessages);
                handleCloseMessages();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleCloseMessagesDisplay = () => {
        setShowMessages(false);
    }

    return (
        <div id="messageContainer">
            <button type="button" className="Modal-close" onClick={handleCloseMessagesDisplay}>X</button>
            <div className="messageButtons newBtn">
                <button type="button" onClick={handleCreateNew}>Create a new message</button>
                <button type="button" onClick={handleGenerateAI} disabled={loading}>Generate with AI</button>
            </div>            
            <div id="messageHeader">
                <h2>{campaign.campaign_name}</h2>
                <p data-tip={campaign.campaign_description}>
                    {campaign.campaign_description.length > 250
                    ? campaign.campaign_description.substring(0, 250) + "..."
                    : campaign.campaign_description}
                </p>
            </div>

            <Tooltip effect="solid" place="top" />
            
            <>
                <Modal isOpen={IsWorkingWithMessage} onRequestClose={handleCloseMessages}>
                    {IsWorkingWithMessage && !isEditing && (<CreateMessage {...{ apiServer, campaign, handleChange, handleCreateNew, handleImageUpload, handleSaveMsg, messages, newMessage, authorizeMessage, deleteMessage, handleCloseMessages, deleteMessageMedia }} />)}
                    {IsWorkingWithMessage && isEditing && (<EditMessage {...{ apiServer, campaign, messageData, handleEditChange, handleCreateNew, handleImageUpload, handleSaveMsg, convertToLocaleTime, handleCloseMessages, deleteMessageMedia }} />)}
                </Modal>
            </>

            <div className='messageHolder'>
                {/* {loading ? "Generating AI Messages..." : "Generate AI Messages"} */}
                <div id="liveMsgContainer" className='campaignMessageContainer'>
                    <h2>Live, Approved Messages</h2>
                    <div className="campaignMessages" >
                        {Array.isArray(messages) && (
                            messages
                                .filter(message => message.message_status === "1")
                                .map(message => (
                                    <div className="live_message message" key={message.msg_id}>
                                        <h3>{message.message_title}</h3>
                                        <p>{message.message}</p>
                                        <h3><Countdown key={message.msg_id} message={message} /></h3>
                                        <div className="messageButtons">
                                            <button onClick={() => deauthorizeMessage(message.msg_id)}>Disapprove</button>
                                            <button onClick={() => prefaceEditMessage(message)}>Edit</button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this message?");
                                                if (confirmDelete) {
                                                    deleteMessage(message.msg_id);
                                                }
                                            }}>Delete</button>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </div>

                <div id="aiMsgContainer" className='campaignMessageContainer'>
                    <h2>Generated Messages</h2>
                    <div className="campaignMessages">
                        {Array.isArray(messages) &&
                            messages
                                .filter(message => message.message_status === "0")
                                .map(message => (
                                    <div className="ai_message message" key={message.msg_id}>
                                        <h3>{message.message_title}</h3>
                                        <p>{message.message}</p>
                                        <h3><Countdown key={message.msg_id} message={message} /></h3>
                                        {/* <p>{message.message_send_UTC}</p> */}
                                        <div className="messageButtons">
                                            <button onClick={() => authorizeMessage(message.msg_id)}>Approve</button>
                                            <button onClick={() => prefaceEditMessage(message)}>Edit</button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this message?");
                                                if (confirmDelete) {
                                                    deleteMessage(message.msg_id);
                                                }
                                            }}>Delete</button>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Message Statuses
// INACTIVE
// ACTIVE
// GENERATED
// COMPLETED
// FAILED

const CreateMessage = ({handleChange, handleCreateNew, handleImageUpload, handleSaveMsg, newMessage, handleCloseMessages, deleteMessageMedia }) => {

    const smsCharacterLimit = 160;
    const [characterCount, setCharacterCount] = useState(0);
    const [messagesInfo, setMessagesInfo] = useState({ messageCount: 0, exceedsLimit: false });
  
    useEffect(() => {
        setMessagesInfo(calculateMessageInfo(characterCount));
    }, [characterCount]);
  
    const calculateMessageInfo = (count) => {
        const messageCount = Math.ceil(count / smsCharacterLimit);
        const exceedsLimit = count > smsCharacterLimit;
        return { messageCount, exceedsLimit };
    };

    const handleSave = (e) => {
        handleSaveMsg(e, newMessage, true);
    }

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        setCharacterCount(inputValue.length);
        handleChange(e);
    };

    return (
        <div className="messageContainer">
            <button type="button" className="Modal-close" onClick={handleCloseMessages}>X</button>
            <h1>Create Message</h1>
            <label>Title</label>
            <input
                type="text"
                name="message_title"
                placeholder="Message title"
                defaultValue={newMessage.title}
                onChange={handleChange}
            />
            <label>Content</label>
            <textarea
                name="message"
                placeholder="Message content"
                defaultValue={newMessage.message}
                onChange={handleMessageChange}
            ></textarea>
            <p className='info'>We include <span>Txt 2 STOP</span> at the end of all of our messages</p>            
            <div>
                <p>Character Count: {characterCount}</p>
                <p>
                    {messagesInfo.exceedsLimit
                        ? `Exceeds SMS character limit (${smsCharacterLimit} characters)`
                        : `Remaining Characters: ${smsCharacterLimit - characterCount}`}
                </p>
                <p>Number of Messages: {messagesInfo.messageCount}</p>
            </div>                

            <label>Attach media</label>
            <input
                type="file"
                name="media"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, true)}
            />
            <label>Send Date</label>
            <input
                type="datetime-local"
                name="message_send_UTC"
                defaultValue={newMessage.sendDate}
                onChange={handleChange}
            />
            <div className="msg_btn_holder">
                <input type="button" name="save_btn" defaultValue="save" onClick={handleSave} />
                <input type="button" name="cancel_btn" defaultValue="cancel" onClick={handleCloseMessages} />
            </div>
        </div>
    );
}    

const EditMessage = ({apiServer, campaign, messageData, handleEditChange, handleImageUpload, handleSaveMsg, handleCloseMessages, convertToLocaleTime, deleteMessageMedia }) => {

    const smsCharacterLimit = 160;
    const [characterCount, setCharacterCount] = useState(0);
    const [messagesInfo, setMessagesInfo] = useState({ messageCount: 0, exceedsLimit: false });

    useEffect(() => {
        // Function to calculate message info
        const calculateMessageInfo = (count) => {
            const messageCount = Math.ceil(count / smsCharacterLimit);
            const exceedsLimit = count > smsCharacterLimit;
            return { messageCount, exceedsLimit };
        };
        
        // Calculate initial message info when component first retrieves data
        const initialCharacterCount = messageData?.message?.length || 0;
        const initialMessagesInfo = calculateMessageInfo(initialCharacterCount);

        // Set initial values
        setCharacterCount(initialCharacterCount);
        setMessagesInfo(initialMessagesInfo);
    }, [messageData]);

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        setCharacterCount(inputValue.length);
        handleEditChange(e);
    };    

    const handleSave = (e) => {
        handleSaveMsg(e, messageData, false);
    }

    const handleDeleteImage = (mediaId) => {
        deleteMessageMedia(mediaId);
    }

    return (
        <div className='messageBox'>
            <button type="button" className="Modal-close" onClick={handleCloseMessages}>X</button>
            <h1>Edit Message</h1>
            <label>Title</label>
            <input 
                type="text" 
                name="message_title" 
                placeholder="Message title" 
                defaultValue={messageData?.message_title}
                onChange={handleEditChange}
            />
            <label>Content</label>
            <textarea 
                name="message" 
                placeholder="Message content" 
                defaultValue={messageData?.message}
                onChange={handleMessageChange}
            ></textarea>
            <p className='info'>We include <span>Txt 2 STOP</span> at the end of all of our messages</p>            
            <div>
                <p>Character Count: {characterCount}</p>
                <p>
                    {messagesInfo.exceedsLimit
                        ? `Exceeds SMS character limit (${smsCharacterLimit} characters)`
                        : `Remaining Characters: ${smsCharacterLimit - characterCount}`}
                </p>
                <p>Number of Messages: {messagesInfo.messageCount}</p>
            </div>            
            {messageData?.media && messageData.media.length > 0 && (
                <div className="media-container">
                    {messageData.media.map((media) => (
                        <div key={media.msg_media_id} className="media-item">
                            <img src={apiServer + media.media_src} />
                            <button type="button" className="delete-button" onClick={() => handleDeleteImage(media.msg_media_id)}>X</button>
                        </div>
                    ))}
                </div>
            )}
            <label>Attach media</label>
            <input 
                type="file" 
                name="messageMedia" 
                accept="image/*"
                onChange={(e) => handleImageUpload(e, false)}
            />
            <label>Send Date</label>
            <input 
                type="datetime-local" 
                name="message_send_UTC" 
                defaultValue={convertToLocaleTime(messageData?.message_send_UTC)}
                onChange={handleEditChange}
                min={campaign.campaign_start_date + `T00:00`}
                max={campaign.campaign_end_date + `T00:00`}
            />
    
            <div className='msg_btn_holder'>
                <button type="button" name="save_btn" onClick={handleSave}>Save</button>
                <button type="button" name="cancel_btn" onClick={handleCloseMessages}>Cancel</button>
            </div>
        </div>
    );
}

export default Messages;