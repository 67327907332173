import React, { useEffect, useState } from 'react';

const Countdown = ({ message }) => {
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [targetDate, setTargetDate] = useState(0);

    useEffect(() => {
        // Calculate time remaining
        const targetDate = new Date(message.message_send_UTC);
        const now = new Date();
        const diff = targetDate - now;

        setTimeRemaining(diff);
        setTargetDate(convertToLocaleTime(message.message_send_UTC));

        // Update the remaining time every second
        const interval = setInterval(() => {
            setTimeRemaining(prevTime => prevTime - 1000);
        }, 1000);

        // Cleanup interval on unmount
        return () => clearInterval(interval);

    }, [message]);

    function convertToLocaleTime(utcDateTime) {
        const utcDate = new Date(utcDateTime);
        const year = utcDate.getFullYear();
        const month = String(utcDate.getMonth() + 1).padStart(2, '0');
        const day = String(utcDate.getDate()).padStart(2, '0');
        const hours = String(utcDate.getHours()).padStart(2, '0');
        const minutes = String(utcDate.getMinutes()).padStart(2, '0');

        return `${month}/${day}/${year} @ ${hours}:${minutes}`;
    }

    const getMessageStatus = (message) => {
        let omessage = '';
        const isCreateMessage = message?.message_status == 0;
        const isPastMessage = timeRemaining <= 0;        

        if (isCreateMessage) {
            if (isPastMessage) {
                omessage = 'Message Expired';
            } else {
                omessage = 'Message could be sent';
            }
        } else { //live message
            if (isPastMessage) {
                omessage = 'Already sent on';
            } else {
                omessage = 'Will be sent on';
            }
        }
        
        return omessage;

    };

    // Format the remaining time
    const formatTime = () => {
        const isPastMessage = timeRemaining <= 0;     

        if (isPastMessage) {
            return "Past Send Date";
        }

        const seconds = Math.floor((timeRemaining / 1000) % 60);
        const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
        const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

        return `${days}days ${hours}hours ${minutes}min ${seconds}s`;
    };

    return (
        <div className="countdown">
            <h3>{getMessageStatus(message)}: {targetDate}</h3>
            <p>{formatTime()}</p>
        </div>
    );
};

export default Countdown;