import React, { useContext, useEffect, useState} from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';

const TrustRegistration = ({setTrustId, currentStep, setCurrentStep}) => {
    const { apiUrl, hmac, loading, setLoading, setNewView } = useContext(AppContext);

    /* Step 1: Business Information */
    const [businessName, setBusinessName] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [businessIndustry, setBusinessIndustry] = useState('');
    const [businessRegistrationIdentifier, setBusinessRegistrationIdentifier] = useState('');
    const [businessTaxId, setBusinessTaxId] = useState('');
    const [businessIdentity, setBusinessIdentity] = useState('');
    const [businessRegionsOfOperation, setBusinessRegionsOfOperation] = useState('');
    

    /* Step 2: Business Address */
    const [businessAddress, setBusinessAddress] = useState('');
    const [businessAddress2, setBusinessAddress2] = useState('');
    const [businessCity, setBusinessCity] = useState('');
    const [businessState, setBusinessState] = useState('');
    const [businessZip, setBusinessZip] = useState('');

    /* Step 3: Business Contact Information */
    const [businessContactName, setBusinessContactName] = useState('');
    const [businessContactEmail, setBusinessContactEmail] = useState('');
    const [businessContactPhone, setBusinessContactPhone] = useState('');


    const validateForm = (formId) => {
        const form = document.querySelector('#step_' + formId + '_form');
        if (!form.checkValidity()) {
            form.reportValidity();
            return false;;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        try {
            /*OLD VALIDATION - STILL TESTING NEW ONE */
            // const form = document.querySelector('#TrustForm');
            // if (!form.checkValidity()) {
            //     form.reportValidity();
            //     return ;
            // }

            setLoading(true);
    
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'createTrustAccount');
            formData.append('businessName', businessName);
            formData.append('websiteUrl', websiteUrl);
            formData.append('businessType', businessType);
            formData.append('businessIndustry', businessIndustry);
            formData.append('businessRegistrationIdentifier', businessRegistrationIdentifier);
            formData.append('businessIdentity', businessIdentity);
            formData.append('businessRegionsOfOperation', businessRegionsOfOperation);
            // formData.append('businessRegistrationNumber', businessRegistrationNumber); //not used, replaced by tax id?
            formData.append('businessAddress', businessAddress);
            formData.append('businessAddress2', businessAddress2);
            formData.append('businessCity', businessCity);
            formData.append('businessState', businessState);
            formData.append('businessZip', businessZip);
            formData.append('businessTaxId', businessTaxId);
            formData.append('businessContactName', businessContactName);
            formData.append('businessContactEmail', businessContactEmail);
            formData.append('businessContactPhone', businessContactPhone);
    
            const response = await axios.post(apiUrl, formData);
            setTrustId(response.data.data);
            setLoading(false);
            setCurrentStep(currentStep+1);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            {currentStep === 1 && (
                <>
                    <form id="step_1_form">
                    <h3 className='onboarding-title'>Step {currentStep}: Business Registration</h3>
                    <label htmlFor="businessName">Business Name</label>
                    <input
                        type="text"
                        id="businessName"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        required
                    />

                    <label htmlFor="websiteUrl">Website URL</label>
                    <input
                        type="text"
                        id="websiteUrl"
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                        required
                    />

                    <label htmlFor="businessType">Business Type</label>
                    <select id="businessType" value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
                        required
                        <option value="">Select...</option>
                        {/* <option value="soleProprietorship">Sole Proprietorship</option> */}
                        <option value="Partnership">Partnership</option>
                        <option value="Limited Liability Corporation">Limited Liability Corporation</option>
                        <option value="Co-operative">Co-operative</option>
                        <option value="Non-profit Corporation">Non-profit Organization</option>
                        <option value="Corporation">Corporation</option>
                        {/* <option value="government">Government Entity</option> */}
                    </select>

                    <label htmlFor="businessIndustry">Business Industry</label>
                    <select
                        id="businessIndustry"
                        value={businessIndustry}
                        onChange={(e) => setBusinessIndustry(e.target.value)}
                        required
                    >
                        <option value="">Select...</option>
                        <option value="AUTOMOTIVE">Automotive</option>
                        <option value="AGRICULTURE">Agriculture</option>
                        <option value="BANKING">Banking</option>
                        <option value="CONSTRUCTION">Construction</option>
                        <option value="CONSUMER">Consumer</option>
                        <option value="EDUCATION">Education</option>
                        <option value="ENGINEERING">Engineering</option>
                        <option value="ENERGY">Energy</option>
                        <option value="OIL_AND_GAS">Oil and Gas</option>
                        <option value="FAST_MOVING_CONSUMER_GOODS">Fast Moving Consumer Goods</option>
                        <option value="FINANCIAL">Financial</option>
                        <option value="FINTECH">Fintech</option>
                        <option value="FOOD_AND_BEVERAGE">Food and Beverage</option>
                        <option value="GOVERNMENT">Government</option>
                        <option value="HEALTHCARE">Healthcare</option>
                        <option value="HOSPITALITY">Hospitality</option>
                        <option value="INSURANCE">Insurance</option>
                        <option value="LEGAL">Legal</option>
                        <option value="MANUFACTURING">Manufacturing</option>
                        <option value="MEDIA">Media</option>
                        <option value="ONLINE">Online</option>
                        <option value="PROFESSIONAL_SERVICES">Professional Services</option>
                        <option value="RAW_MATERIALS">Raw Materials</option>
                        <option value="REAL_ESTATE">Real Estate</option>
                        <option value="RELIGION">Religion</option>
                        <option value="RETAIL">Retail</option>
                        <option value="JEWELRY">Jewelry</option>
                        <option value="TECHNOLOGY">Technology</option>
                        <option value="TELECOMMUNICATIONS">Telecommunications</option>
                        <option value="TRANSPORTATION">Transportation</option>
                        <option value="TRAVEL">Travel</option>
                        <option value="ELECTRONICS">Electronics</option>
                        <option value="NOT_FOR_PROFIT">Not for Profit</option>
                    </select>
                    <label htmlFor="businessRegistrationIdentifier">Business Registration Identifier</label>
                    <select
                        id="businessRegistrationIdentifier"
                        value={businessRegistrationIdentifier}
                        onChange={(e) => setBusinessRegistrationIdentifier(e.target.value)}
                        required
                    >
                        <option value="">Select...</option>
                        <option value="EIN">Employer Identification Number (EIN)</option>
                        <option value="TIN">Tax Identification Number (TIN)</option>
                        <option value="DUNS">D-U-N-S Number</option>
                        <option value="SSN">Social Security Number (SSN)</option>
                        {/* Add more registration identifier options as needed */}
                    </select>

                    {/* Render additional form fields based on the selected businessRegistrationIdentifier */}
                    {businessRegistrationIdentifier === "EIN" && (
                        <div>
                        <label htmlFor="businessTaxId">Business Tax ID</label>
                        <input
                            type="text"
                            id="businessTaxId"
                            value={businessTaxId}
                            onChange={(e) => setBusinessTaxId(e.target.value)}
                            required
                        />
                        </div>
                    )}

                    <label htmlFor="businessIdentity">Business Identity</label>
                    <select id="businessIdentity" value={businessIdentity} onChange={(e) => setBusinessIdentity(e.target.value)}>
                        required
                    <option value="">Select...</option>
                        <option value="direct_customer">Direct Customer</option>
                        <option value="isv_reseller_or_partner">ISV Reseller or Partner</option>
                        <option value="unknown">Unknown</option>
                    </select>
                    </form>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <h3>Business Location</h3>
                    <form id="step_2_form">
                    <label htmlFor="businessRegionsOfOperation">Business Regions of Operation</label>
                    <select
                        id="businessRegionsOfOperation"
                        value={businessRegionsOfOperation}
                        onChange={(e) => setBusinessRegionsOfOperation(e.target.value)}
                        required
                    >
                        {/* Options for business_regions_of_operation */}
                        <option value="">Select...</option>
                        <option value="AFRICA">Africa</option>
                        <option value="ASIA">Asia</option>
                        <option value="EUROPE">Europe</option>
                        <option value="LATIN_AMERICA">Latin America</option>
                        <option value="USA_AND_CANADA">USA and Canada</option>
                    </select>


                    {/* <label htmlFor="businessRegistrationNumber">Business Registration Number</label>
                    <input
                        type="text"
                        id="businessRegistrationNumber"
                        value={businessRegistrationNumber}
                        onChange={(e) => setBusinessRegistrationNumber(e.target.value)}
                        required
                    /> */}

                    <label htmlFor="businessAddress">Business Address</label>
                    <input
                        type="text"
                        id="businessAddress"
                        value={businessAddress}
                        onChange={(e) => setBusinessAddress(e.target.value)}
                        required
                    />
                    <label htmlFor="businessAddress2">Suite</label>
                    <input
                        type="text"
                        id="businessAddress2"
                        value={businessAddress2}
                        onChange={(e) => setBusinessAddress2(e.target.value)}
                        
                    />            
                    <label htmlFor="businessCity">Business City</label>
                    <input
                        type="text"
                        id="businessCity"
                        value={businessCity}
                        onChange={(e) => setBusinessCity(e.target.value)}
                        required
                    />
                    <label htmlFor="businessState">Business State</label>
                    <select id="businessState" value={businessState} onChange={(e) => setBusinessState(e.target.value)}>
                        required
                        <option value="">Select...</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                    <label htmlFor="businessZip">Business Zip</label>
                    <input
                        type="number"
                        id="businessZip"
                        value={businessZip}
                        onChange={(e) => setBusinessZip(e.target.value)}
                        required
                    />
                </form>                        
                </>
            )}

            {currentStep === 3 && (
                <>
                    <h3>Business Contact Information</h3>
                    <form id="step_3_form">
                    <label htmlFor="businessContactName">Business Contact Name</label>
                    <input
                        type="text"
                        id="businessContactName"
                        value={businessContactName}
                        onChange={(e) => setBusinessContactName(e.target.value)}
                        required
                    />

                    <label htmlFor="businessContactEmail">Business Contact Email</label>
                    <input
                        type="email"
                        id="businessContactEmail"
                        value={businessContactEmail}
                        onChange={(e) => setBusinessContactEmail(e.target.value)}
                        required
                    />

                    <label htmlFor="businessContactPhone">Business Contact Phone</label>
                    <input
                        type="tel"
                        id="businessContactPhone"
                        value={businessContactPhone}
                        onChange={(e) => setBusinessContactPhone(e.target.value)}
                        required
                    />
                    </form>
                </>
            )}
            <div className="onboarding-navigation">
                {currentStep !== 1 && (
                    <button className="onboarding-previous" onClick={() => {
                        setCurrentStep(currentStep-1);
                    }
                }>Previous</button>)}
                {currentStep === 3 ? (
                    <button className="onboarding-save" onClick={() => {
                        if (validateForm(currentStep)) {setCurrentStep(currentStep+1); handleSubmit();}}}>Save</button>
                ) : (
                    <button className="onboarding-next" onClick={() => {if (validateForm(currentStep)) {
                        setCurrentStep(currentStep+1);
                    }}}>Next</button>
                )}
            </div>
        </div>
    );
};

export default TrustRegistration;