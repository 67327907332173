import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Routes, Route } from "react-router-dom";
import { AppProvider } from './AppContext';
import './App.css';

import Header from './components/jsx/Header';
import Main from './components/jsx/Main';
import Auth from './components/jsx/Auth';
import Footer from './components/jsx/Footer';

const App = () => {    
    return (
        <AppProvider>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/auth" element={<Auth />} />
                    {/* Add more routes here */}
                </Routes>
                {/* <Footer /> */}
            </div>
        </AppProvider>
    );
}

export default App;