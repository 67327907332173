import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/outgoingcalls.css';

const OutgoingCalls = () => {
    const navigate = useNavigate();
    const { apiUrl, hmac, setLoading, setNewView } = useContext(AppContext);

    // State initialization
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [campaignName, setCampaignName] = useState('');
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [scheduleDate, setScheduleDate] = useState('');
    const [contactGroups, setContactGroups] = useState([]);
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [campaignId, setCampaignId] = useState(null);
    const [userPhones, setUserPhones] = useState([]);
    const [selectedUserPhone, setSelectedUserPhone] = useState('');


    /* campaign details stuff */

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [detailedLogs, setDetailedLogs] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const fetchCampaignDetails = useCallback(async (campaignId) => {
        setLoadingDetails(true);
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getCampaignDetails');
            formData.append('campaignId', campaignId);
    
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setDetailedLogs(response.data.data);
            } else {
                // show Errors on page
            }
        } catch (error) {
            console.error('Error fetching campaign details:', error);
        } finally {
            setLoadingDetails(false);
        }
    }, [apiUrl, hmac]);
    

    // Fetch functions
    const fetchContactGroups = useCallback(async () => {
        setIsLoadingGroups(true);
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listGroups');

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setContactGroups(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching contact groups:', error);
        } finally {
            setIsLoadingGroups(false);
        }
    }, [apiUrl, hmac]);


    const fetchUserPhones = useCallback(async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listMyPhoneNumbers');
    
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setUserPhones(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching user phones:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, hmac, setLoading]);

    
    const fetchCampaigns = useCallback(async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getOutgoingVoiceCampaigns');

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setCampaigns(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, hmac, setLoading]);

    useEffect(() => {
        // Fetch required data on component mount
        fetchContactGroups();
        fetchCampaigns();
        fetchUserPhones();
    }, [fetchContactGroups, fetchCampaigns, fetchUserPhones]);

    // Form reset function
    const resetForm = () => {
        setCampaignId(null);
        setCampaignName('');
        setSelectedGroups([]);
        setScheduleDate('');
        setIsEditing(false);
        setIsCollapsed(true);
    };

    // Form validation
    const isFormValid = () => {
        return campaignName && selectedUserPhone && scheduleDate && selectedGroups.length > 0;
    };

    // Create or update campaign
    const handleCreateOrUpdateCampaign = async () => {
        if (!isFormValid()) {
            alert('All fields are required before submission.');
            return;
        }

        setLoading(true);
        const action = isEditing ? 'updateOutgoingVoiceCampaign' : 'createOutgoingVoiceCampaign';
        const formData = new FormData();
        formData.append('action', action);
        formData.append('userHmacId', hmac);
        if (isEditing) formData.append('campaignId', campaignId);
        formData.append('campaignName', campaignName);
        formData.append('userPhoneId', selectedUserPhone);
        formData.append('contactGroupIds', selectedGroups.join(','));
        formData.append('scheduleDate', scheduleDate);

        try {
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                fetchCampaigns();
                resetForm();
            } else {
                alert('Failed to save campaign');
            }
        } catch (error) {
            console.error('Error saving campaign:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditCampaign = (campaign) => {
        setCampaignId(campaign.campaign_id);
        setCampaignName(campaign.campaign_name);
    
        try {
            const contactGroupIds = campaign.contact_group_ids ? campaign.contact_group_ids.split(',') : [];
            setSelectedGroups(contactGroupIds);
        } catch (error) {
            console.error('Error parsing contact_group_ids:', error);
            setSelectedGroups([]);
        }
    
        setScheduleDate(campaign.schedule_date);
        setSelectedUserPhone(campaign.user_phone_id);
        setIsEditing(true);
        setIsCollapsed(false);
        setSelectedCampaign(null);
    };

    // Delete campaign
    const handleDeleteCampaign = async (campaignId) => {
        if (!window.confirm('Are you sure you want to delete this campaign?')) return;

        setLoading(true);
        const formData = new FormData();
        formData.append('action', 'deleteOutgoingVoiceCampaign');
        formData.append('userHmacId', hmac);
        formData.append('campaignId', campaignId);

        try {
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                fetchCampaigns();
            } else {
                alert('Failed to delete campaign');
            }
        } catch (error) {
            console.error('Error deleting campaign:', error);
        } finally {
            setLoading(false);
        }
    };

    // Toggle collapse
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleViewCampaignDetails = async (campaign) => {
        setSelectedCampaign(campaign);
        await fetchCampaignDetails(campaign.campaign_id);
    };    

    // Handle contact group click
    const handleGroupClick = (contactGroupId) => {
        setSelectedGroups(prevSelectedGroups =>
            prevSelectedGroups.includes(contactGroupId)
                ? prevSelectedGroups.filter((group) => group !== contactGroupId)
                : [...prevSelectedGroups, contactGroupId]
        );
    };

    return (
        <div className="content_container">
            <button className="toggle-collapse-btn newBtn" onClick={toggleCollapse}>
                {isCollapsed ? 'Create A Calling Campaign' : 'Close Campaign Creator'}
            </button>

            {isCollapsed && (
                <div className="existing-campaigns">
                    <h3>Current Campaigns</h3>
                    {campaigns.length === 0 ? (
                        <p>No campaigns found.</p>
                    ) : (
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Campaign Name</th>
                                        <th>Contact Groups</th>
                                        <th>Schedule Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {campaigns.map((campaign) => (
                                        <tr key={campaign.campaign_id}>
                                            <td>{campaign.campaign_name}</td>
                                            <td>
                                                {campaign.contact_group_ids.split(',').map((groupId) => {
                                                    const parsedGroupId = groupId.trim(); // Ensure it's a string
                                                    const group = contactGroups.find((group) => {
                                                        return group.contact_group_id === parsedGroupId;
                                                    });

                                                    return group ? group.contact_group_name : 'Unknown Group';
                                                }).join(', ')}
                                            </td>
                                            <td>{new Date(campaign.schedule_date).toLocaleString()}</td>
                                            <td>
                                                <div className='campaignBtns'>
                                                    <button onClick={() => handleEditCampaign(campaign)}>Edit</button>
                                                    <button onClick={() => handleDeleteCampaign(campaign.campaign_id)}>Delete</button>
                                                    <button onClick={() => handleViewCampaignDetails(campaign)}>View Details</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {selectedCampaign && (
                        <div className="detailed-view">
                            <h3>Details for Campaign: {selectedCampaign.campaign_name}</h3>
                            {loadingDetails ? (
                                <p>Loading details...</p>
                            ) : (
                                <div>
                                    <h4>Call Logs</h4>
                                    {detailedLogs.length === 0 ? (
                                        <p>No call logs available.</p>
                                    ) : (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Timestamp</th>
                                                    <th>Phone Number</th>
                                                    <th>Result</th>
                                                    <th>Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailedLogs.map((log, index) => (
                                                    <tr key={index}>
                                                        <td>{new Date(log.timestamp).toLocaleString()}</td>
                                                        <td>{log.phone_number}</td>
                                                        <td>{log.result}</td>
                                                        <td>{log.duration}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {!isCollapsed && (
                <div className="campaign-form">
                    <div className="form-group">
                        <label>Choose a Campaign Name:</label>
                        <input type="text" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} placeholder="Enter campaign name" />
                    </div>
                    <div className="form-group">
                        <label>Which Phone number would you like to Call from ?</label>
                        <select value={selectedUserPhone} onChange={(e) => setSelectedUserPhone(e.target.value)}>
                            <option value="">Select a phone</option>
                            {userPhones.map((phone) => (
                                <option key={phone.user_phone_id} value={phone.user_phone_id}>{phone.phone_number}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Which of your groups do you want to call</label>
                        <div className="contact-group-bubbles">
                            {contactGroups.map((group) => (
                                <div className={`contact-group-bubble ${selectedGroups.includes(group.contact_group_id) ? 'selected' : ''}`} key={group.contact_group_id} onClick={() => handleGroupClick(group.contact_group_id)}>
                                    {group.contact_group_name}
                                </div>
                            ))}
                        </div>
                        {isLoadingGroups && <p>Loading contact groups...</p>}
                    </div>
                    <div className="form-group">
                        <label>When do you want these calls to take place?</label>
                        <input type="datetime-local" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
                    </div>
                    <div className='campaignBtns'>
                        <button onClick={handleCreateOrUpdateCampaign}>{isEditing ? 'Update Campaign' : 'Create Campaign'}</button>
                        {isEditing && <button onClick={resetForm}>Cancel Edit</button>}
                    </div>
                </div>
            )}


        </div>
    );
};

export default OutgoingCalls;
