import React from 'react';
import '../css/header.css';

function Header() {
    return (
        <header>
            <div className="logo">
                <img src={process.env.PUBLIC_URL + '/final_logo.png'} alt="Logo" />
            </div>
            <div className="contact-info">
                {/* Contact information/livechat links */}
                <p>info@awebllc.com</p>
            </div>
        </header>
    );
}

export default Header;