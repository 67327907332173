import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import PhoneNumbers from './PhoneNumbers';
import MyPhoneNumbers from './MyPhoneNumbers';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/communications.css';

const Communications = () => {
    const [showMyNumbers, setShowMyNumbers] = useState(true);

    const handleToggleNumbers = () => {
        setShowMyNumbers(!showMyNumbers);
    };

    return (
        <div className='content_container'>
            <div className='options'>
                <button className='newBtn' onClick={handleToggleNumbers}>{showMyNumbers ? 'Buy New Phone Number' : 'View Phone Numbers'}</button>
            </div>
            {showMyNumbers ? <MyPhoneNumbers /> : <PhoneNumbers />}
        </div>
    );
};

export default Communications;