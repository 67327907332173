import React, { createContext, useState } from 'react';

// Create the context
export const AppContext = createContext();

// Create the provider component
export const AppProvider = ({ children }) => {
    const [reloadData, setReloadData] = useState(false);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [filteredAvailableGroups, setFilteredAvailableGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hmac, setHmac] = useState();
    const [isSavable, setIsSavable] = useState(false);

    const [newView, setNewView] = useState('Main');
    const [selectedContactPhone, setSelectedContactPhone] = useState();
    

    //const apiUrl = 'http://192.168.1.2/development/astrotext.live/api/api.php';
    //const apiServer = 'http://192.168.1.2/development/astrotext.live/api/';
    const apiUrl = 'https://api.astrotext.live/api.php';
    const apiServer = 'https://api.astrotext.live/';
    



    return (
        <AppContext.Provider value={{ apiServer, apiUrl, hmac, setHmac, newView, setNewView, reloadData, setReloadData, availableGroups, setAvailableGroups, selectedGroups, setSelectedGroups, loading, setLoading, filteredAvailableGroups, setFilteredAvailableGroups, isSavable, setIsSavable, selectedContactPhone, setSelectedContactPhone }}>
            {children}
        </AppContext.Provider>
    );
}
