import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/phonecalltranscripts.css';

const PhoneCallTranscriptions = ({ phoneId }) => {
    const { apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [transcriptions, setTranscriptions] = useState([]);
    const [selectedTranscription, setSelectedTranscription] = useState(null);

    useEffect(() => {
        fetchPhoneCallTranscriptions(phoneId);
    }, [phoneId]);

    const fetchPhoneCallTranscriptions = async (phoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getPhoneCallTranscriptions');
            formData.append('phoneNumberId', phoneId);
            const response = await axios.post(apiUrl, formData);
            
            if (response.data.status === 'success') {
                setTranscriptions(response.data.data);
            } else {
                setTranscriptions(null);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleClickTranscription = (transcription) => {
        console.log(transcription);

        setSelectedTranscription(transcription);
    };

    return (
        <div className="phone-call-transcriptions-container">
            {transcriptions ? (
                <div className="transcriptions-list">
                    <h2>Phone Call Transcriptions</h2>
                    <ul>
                        {transcriptions.map(transcription => (
                            <li key={transcription.conversationId} onClick={() => handleClickTranscription(transcription)}>
                                {transcription.userPhoneNumber} - {transcription.filename}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <h4>No Phone Calls Found...</h4>
            )}

            {selectedTranscription && (
                <div className="transcription-content">
                    {/* <h2>({selectedTranscription.filename})</h2> */}
                    {selectedTranscription.messages.map((message, index) => (
                        <div key={index} className={`message ${message.role}`}>
                            <strong>{message.role}</strong>
                            <p>{message.content}</p>
                            <small>{new Date(message.timestamp).toLocaleString()}</small>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhoneCallTranscriptions;