import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/importcontacts.css';


const ImportContacts = ({ setCurrentPage, groupId, onClose }) => {
    const { apiUrl, hmac, loading, setLoading, setReloadData } = useContext(AppContext);

    const handleFileDrop = async (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        if (!file) return;

        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('action', 'importContacts');
            formData.append('userHmacId', hmac);
            formData.append('contactImport', file);
            formData.append('group_id', groupId);


            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setReloadData(true);
            setCurrentPage(1);
            onClose();
            setLoading(false);
        } catch (error) {
            console.error('Error uploading contacts:', error);
            setLoading(false);
        }
    };

    return (
        <div className="overlay">
          <div className="modal">
            <div
              className="drop-zone"
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
            >
              Drop CSV file here to import contacts
              <button className="close-button" onClick={onClose}>Close</button>
            </div>
            {loading && <div className="loading">Loading...</div>}
          </div>
        </div>
    );
};

export default ImportContacts;