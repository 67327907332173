import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/AssistantKnowledgebaseModal.css';

const AssistantKnowledgeBaseModal = ({ 
    onClose,
    getAssistantKnowledgebases,
    phoneNumber,
    knowledgebaseType, 
    setKnowledgeBaseType, 
    knowledgebaseText, 
    setKnowledgeBaseText, 
    files, 
    setFiles,
    isEditMode,
    handleSaveKnowledge
}) => {

    const { apiUrl, hmac, setLoading} = useContext(AppContext);

    const phoneId = phoneNumber.user_phone_id;

    const knowledgeBaseOptions = [
        "FAQ",
        "Documentation",
        "Tutorial",
        "Troubleshooting",
        "Best Practices",
        "Use Cases",
        "Glossary",
        "Policy Information",
        "Training Material",
        "Other" // Adding "Other" at the bottom
    ];

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {isEditMode ? <h2>Update Knowledge Base</h2> : <h2>Add to Knowledge Base </h2>}
                <label>
                    Type of Knowledge:
                </label>                    
                    <select 
                        value={knowledgebaseType} 
                        onChange={(e) => setKnowledgeBaseType(e.target.value)}
                    >
                        <option value="">Select Type</option> {/* Default option */}
                        {knowledgeBaseOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                <label>
                    Knowledgebase Text:
                    <textarea
                        value={knowledgebaseText}
                        onChange={(e) => setKnowledgeBaseText(e.target.value)}
                        placeholder="Enter knowledgebase text"
                    />
                </label>
                <label>
                    Upload Files:
                    <input
                        type="file"
                        accept=".txt, .pdf, .jpg, .jpeg, .png"
                        multiple // Allow multiple file uploads
                        onChange={handleFileChange}
                    />
                </label>
                <div className="modal-actions">
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={handleSaveKnowledge}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default AssistantKnowledgeBaseModal;