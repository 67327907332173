import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate} from "react-router-dom";
import axios from 'axios';
import '../css/confirmtrust.css';

const ConfirmTrust = ({setNewView}) => {
    const navigate = useNavigate();
    const { apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [completedProfile, setCompletedProfile] = useState(null);
    const [trustData, setTrustData] = useState(null);
    const [trustId, setTrustId] = useState(null);


    const fetchData = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'showTrusts');
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setTrustData(response.data.data);
            } else {
                console.log('no trusts found');
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleProfileConfirmation = async (trustId) => {
        setTrustId(trustId);
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'confirmTrust');
            formData.append('trustId', trustId);
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                // setNewView('PhoneNumbers');
            } else {
                const errorData = response.data.data;
                // Assuming the error data is an array
                const errorMessage = errorData.map(error => `${error.field}: ${error.failedReason}`).join('\n');
                alert(errorMessage);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const handleDeleteProfile = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'deleteTrust');
            formData.append('trustId', trustId);
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setTrustData(response.data.data);
            } else {

            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);


    }

    return (
        <>
            <h3 className="onboarding-title">Almost finished: Confirm your completed profile</h3>
            <div id="confirmProfile">
                {trustData &&
                    trustData.map((trust) => (
                        <div className="trustProfile" key={trust.trust_id}>
                            <h3 className="subtitle">Trust Registration: {trust.trust_id}</h3>
                            <p>Business Name: {trust.business_name}</p>
                            <p>Website URL: {trust.website_url}</p>
                            <p>Business Type: {trust.business_type}</p>
                            <p>Business Industry: {trust.business_industry}</p>
                            {/* <p>Registration Identifier: {trust.registration_identifier}</p>
                            <p>Business Identity: {trust.business_identity}</p>
                            <p>Regions of Operation: {trust.regions_of_operation}</p>
                            <p>Registration Number: {trust.registration_number}</p>
                            <p>Business Address: {trust.business_address}</p>
                            <p>Business Address2: {trust.business_address2}</p>
                            <p>Business City: {trust.business_city}</p>
                            <p>Business State: {trust.business_state}</p>
                            <p>Business Zip: {trust.business_zip}</p> */}
                            <p>Tax ID: {trust.tax_id}</p>
                            <p>Business Contact Name: {trust.business_contact_name}</p>
                            <p>Business Email: {trust.business_email}</p>
                            <p>Business Phone: {trust.business_phone}</p>
                            {/* <p>Updated UTC: {trust.updated_UTC}</p> */}
                            <button
                                className="confirm-btn newBtn"
                                onClick={() => {handleProfileConfirmation(trust.trust_id);}}
                            >
                                Submit profile for Review
                            </button>&nbsp;
                            <button
                                className="delete-btn newBtn"
                                onClick={() => {setTrustId(trust.trust_id); handleDeleteProfile();fetchData();}}
                            >
                                Delete this Profile
                            </button>                        
                            {/* Add more fields as needed */}
                    
                            {trust.authorizedMembers && (
                                <div>
                                    <h6 className="authorized-members">Authorized Members:</h6>
                                    {trust.authorizedMembers.map((member) => (
                                        <div key={member.rep_id}>
                                        <p>{member.title} {member.first_name} {member.last_name}, {member.job_position}</p>
                                        <p>{member.email}</p>
                                        <p>Phone: {member.phone}</p>
                                        {/* <p>Created UTC: {member.created_UTC}</p> */}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </>
    );
};

export default ConfirmTrust;