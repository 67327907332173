import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import PhoneCallTranscriptions from './phoneCallTranscriptions';
import '../css/incoming.css';


const Incoming = () => {
    const { apiUrl, hmac, loading, setLoading, selectedContactPhone} = useContext(AppContext);

    const [selectedPhoneId, setSelectedPhoneId] = useState(selectedContactPhone.user_phone_id);
    const [showCommunicationTypes, setShowCommunicationTypes] = useState(true);
    const [selectedCommunicationType, setSelectedCommunicationType] = useState('sms');
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState([]);
    const [myPhoneNumber, setFromPhone] = useState(null);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        fetchConversationsFromAPI(selectedPhoneId);
    }, [selectedPhoneId]);

    const fetchConversationsFromAPI = async (phoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getIncoming');
            formData.append('phoneNumberId', phoneId);
            const response = await axios.post(apiUrl, formData);

            if (response.data.status === 'success') {
                const data = response.data.data;
                const apiConversations = Object.values(data);
                setConversations(apiConversations);    
            } else {
                setConversations(null);
            }
            
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchCompleteConversation = async (phoneNumber) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getConversation');
            // formData.append('phoneNumberId', phoneId);
            formData.append('conversationPhoneNumber', phoneNumber);
            const response = await axios.post(apiUrl, formData);
            const completeConversation = Object.values(response.data.data ?? []);
            setCurrentConversation(completeConversation);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSelectCommunicationType = (type) => {
        setSelectedCommunicationType(type); // Update selected communication type
        type === 'sms' ? fetchConversationsFromAPI(selectedPhoneId) : setActiveConversation(null);
        // Clear active conversation for phone call transcriptions
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'sendSMS');
                formData.append('phoneNumberId', selectedPhoneId);
                formData.append('conversationPhoneNumber', activeConversation);
                formData.append('smsMessage', newMessage);
                const response = await axios.post(apiUrl, formData);
                fetchCompleteConversation(activeConversation);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
            setNewMessage('');
        }
    };

    const handleClickConversation = async (conversationId, phoneNumber) => {
        setActiveConversation(phoneNumber); // Update activeConversation to display the selected phone number
        fetchCompleteConversation(phoneNumber);
    };

    return (
        <div id="incomingContainer" className='content_container'>
            {showCommunicationTypes && (
                <div className="communication-types">
                    <div>
                        <button onClick={() => handleSelectCommunicationType('sms')}>
                            View Text Messages
                        </button>
                        <button onClick={() => handleSelectCommunicationType('calls')}>
                            View Phone Call Transcriptions
                        </button>
                    </div>
                </div>
            )}
            {showCommunicationTypes && selectedCommunicationType === 'sms' && (
                <div className="conversation-container">
                    <div className="conversations-sidebar">
                        {conversations ? (
                            <ul>
                                {conversations?.map(conversation => (

                                    <li key={conversation.id}>
                                        <button className="conversation-button" onClick={() => handleClickConversation(conversation.id, conversation.from)}>
                                            {conversation.from}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : ("No Text Messages Found...")}
                    </div>
                    {Array.isArray(conversations) && (
                        activeConversation ? (
                            <>
                                <div className="conversation-content">
                                    <h2>SMS Conversation With: {activeConversation}</h2>
                                    <div className="messages">
                                        {currentConversation.map(message => (
                                            <div key={message.id} className={`message ${message.from === activeConversation ? 'received' : 'sent'}`}>
                                                <strong>{message.from} ({message.msg_UTC}): </strong>
                                                <p>{message.body}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="message-input">
                                        <input
                                            type="text"
                                            value={newMessage}
                                            onChange={e => setNewMessage(e.target.value)}
                                            placeholder="Type your message"
                                        />
                                        <button onClick={handleSendMessage}>Send</button>
                                    </div>
                                </div>                                
                            </>
                        ) : (
                            <div>Please select a conversation</div>
                        )
                    )}

                </div>
            )}
            {selectedCommunicationType === 'calls' && (
                <div className="phone-call-transcriptions">
                    <PhoneCallTranscriptions phoneId={selectedPhoneId} />
                </div>
            )}
        </div>
    );
};

export default Incoming;