import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { CreateGroup, EditGroup, ListGroups } from './ContactGroups';
import '../css/contacts.css';

const Contacts = () => {
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    
    const openCreateModal = () => {
        setCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalOpen(false);
    };

    const openEditModal = (group) => {
        setSelectedGroup(group);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedGroup(null);
        setEditModalOpen(false);
    };


    return (
        <div id="contact_container" className="content_container">
            <button onClick={openCreateModal} className='newBtn'>Create New Group</button>
            <CreateGroup isOpen={isCreateModalOpen} closeModal={closeCreateModal} />
    
            {selectedGroup && (
                <EditGroup group={selectedGroup} isOpen={isEditModalOpen} closeModal={closeEditModal} />
            )}

            {/* List Component*/}
            <ListGroups openEditModal={openEditModal} />
        </div>
    );
};

export default Contacts;