import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
// import '../css/confirmtrust.css';

const useCase = () => {
    const { apiUrl, hmac, setLoading, setNewView } = useContext(AppContext);
    const [useCases, setUseCases] = useState([]);
    const [selectedUseCase, setSelectedUseCase] = useState(null);
    const [formData, setFormData] = useState({
        messaging_service_sid: '',
        brand_registration_sid: '',
        description: '',
        message_flow: '',
        message_samples: [],
        us_app_to_person_usecase: '',
        has_embedded_links: false,
        has_embedded_phone: false,
        opt_in_message: '',
        opt_out_message: '',
        help_message: '',
        opt_in_keywords: '',
        opt_out_keywords: '',
        help_keywords: '',
    });

    const fetchA2PUseCases = async () => {
        try {
        const response = await axios.get(`${apiUrl}/fetch-a2p-use-cases`, {
            headers: {
            Authorization: hmac,
            },
        });

        // Handle the response and update the state accordingly
        setUseCases(response.data.us_app_to_person_usecases);
        } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error fetching A2P use cases:', error);
        } finally {
        setLoading(false);
        }
    };

    const handleUseCaseSelection = (useCase) => {
        setSelectedUseCase(useCase);
        // Perform any additional actions based on the selected use case
        // ...
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
        await axios.post(`${apiUrl}/submit-use-case-form`, formData, {
            headers: {
            Authorization: hmac,
            },
        });

        // Handle the successful form submission
        setNewView('success');
        } catch (error) {
        // Handle any errors that occur during the form submission
        console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        // Call the function to fetch the A2P use cases when the component mounts
        fetchA2PUseCases();
    }, []);

    return (
        <div>
            <h2>Available A2P Use Cases</h2>

            {useCases.map((useCase) => (
                <div key={useCase.code}>
                <h3>{useCase.name}</h3>
                <p>{useCase.description}</p>
                <p>Post Approval Required: {useCase.post_approval_required ? 'Yes' : 'No'}</p>
                <button onClick={() => handleUseCaseSelection(useCase)}>Select Use Case</button>
                </div>
            ))}

            {selectedUseCase && (
                <form onSubmit={handleSubmit}>
                <h2>Selected Use Case</h2>
                <h3>{selectedUseCase.name}</h3>
                <p>{selectedUseCase.description}</p>
                <p>Post Approval Required: {selectedUseCase.post_approval_required ? 'Yes' : 'No'}</p>

                <h2>Fill out the form:</h2>

                <label>
                    Messaging Service SID:
                    <input
                    type="text"
                    name="messaging_service_sid"
                    value={formData.messaging_service_sid}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Brand Registration SID:
                    <input
                    type="text"
                    name="brand_registration_sid"
                    value={formData.brand_registration_sid}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Description:
                    <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Message Flow:
                    <textarea
                    name="message_flow"
                    value={formData.message_flow}
                    onChange={handleChange}
                    />
                </label>

                {/* Add remaining form fields */}
                {/* Note: You may need to adjust the field types and names to match the required format. */}
                <label>
                    Message Samples:
                    <textarea
                    name="message_samples"
                    value={formData.message_samples}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    U.S. App to Person Usecase:
                    <input
                    type="text"
                    name="us_app_to_person_usecase"
                    value={formData.us_app_to_person_usecase}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Has Embedded Links:
                    <input
                    type="checkbox"
                    name="has_embedded_links"
                    checked={formData.has_embedded_links}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Has Embedded Phone Numbers:
                    <input
                    type="checkbox"
                    name="has_embedded_phone"
                    checked={formData.has_embedded_phone}
                    onChange={handleChange}
                    />
                </label>

                {/* Add any remaining fields here */}

                <button type="submit">Submit Form</button>
                </form>
            )}
        </div>
    );
};

export default useCase;
