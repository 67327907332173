import React, { useContext, useEffect, useState} from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';


const AuthorizedRepresentative = ({ trustId, currentStep, setCurrentStep}) => {
    const { apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [repFirstName, setRepFirstName] = useState('');
    const [repLastName, setRepLastName] = useState('');
    const [repTitle, setRepTitle] = useState('');
    const [repEmail, setRepEmail] = useState('');
    const [repPhone, setRepPhone] = useState('');
    const [repJobPosition, setRepJobPosition] = useState('');

    const handleSubmit = async () => {
        setLoading(true);
    
        const formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'createAuthorizedRepresentative');
        formData.append('trustId', trustId);
        formData.append('repFirstName', repFirstName);
        formData.append('repLastName', repLastName);
        formData.append('repTitle', repTitle);
        formData.append('repEmail', repEmail);
        formData.append('repPhone', repPhone);
        formData.append('repJobPosition', repJobPosition);

        const response = await axios.post(apiUrl, formData);
        setLoading(false);
        setCurrentStep(currentStep+1);
    };

    return (
        <div>
        <h3>Authorized Representative</h3>

        <label htmlFor="repFirstName">First Name</label>
        <input
            type="text"
            id="repFirstName"
            value={repFirstName}
            onChange={(e) => setRepFirstName(e.target.value)}
        />

        <label htmlFor="repLastName">Last Name</label>
        <input
            type="text"
            id="repLastName"
            value={repLastName}
            onChange={(e) => setRepLastName(e.target.value)}
        />

        <label htmlFor="repTitle">Title</label>
        <input
            type="text"
            id="repTitle"
            value={repTitle}
            onChange={(e) => setRepTitle(e.target.value)}
        />

        <label htmlFor="repEmail">Email</label>
        <input
            type="email"
            id="repEmail"
            value={repEmail}
            onChange={(e) => setRepEmail(e.target.value)}
        />

        <label htmlFor="repPhone">Phone</label>
        <input
            type="tel"
            id="repPhone"
            value={repPhone}
            onChange={(e) => setRepPhone(e.target.value)}
        />

        <label htmlFor="repJobPosition">Job Position</label>
        <select
            id="repJobPosition"
            value={repJobPosition}
            onChange={(e) => setRepJobPosition(e.target.value)}
        >
            <option value="">Select...</option>
            <option value="Director">Director</option>
            <option value="GM">GM</option>
            <option value="VP">VP</option>
            <option value="CEO">CEO</option>
            <option value="CFO">CFO</option>
            <option value="General Counsel">General Counsel</option>
            <option value="Other">Other</option>
        </select>


        {currentStep !== 1 && (
        <button onClick={() => {
            setCurrentStep(currentStep-1);
        }
        }>Previous</button>)}
        <button onClick={handleSubmit}>Next</button>
        </div>
    );
};

export default AuthorizedRepresentative;