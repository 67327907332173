import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate} from "react-router-dom";
import axios from 'axios';
import '../css/auth.css';

const AccountForm = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const checkHmac = () => {
            const hmac = sessionStorage.getItem('hmac_key');
            if (hmac) {
                navigate('/');
            }
        }

        checkHmac();
    });

    return (
        <div className="account-form">
            <div className="form-container">
                    <LoginForm />
                    <CreateAccount />
            </div>
        </div>
    );
};

const LoginForm = () => {
    const { apiUrl, loading, setLoading, hmac, setHmac} = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const navigate = useNavigate();

    const setupSession = (hmac) => {
        setHmac(hmac);
        sessionStorage.setItem('hmac_key', hmac);
    }


    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
            try {
                let formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'login');
                formData.append('email', email);
                formData.append('password', password);
                const response = await axios.post(apiUrl, formData);
                if (response.data.status == 'success') {
                    setupSession(response.data.data);
                    navigate('/');
                } else {
                    const errorMessage = response.data.message;
                    setLoginError(errorMessage); // Set error message to state
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
    };

    return (
        <div className="login-form">
            <h2>Login</h2>
            {loginError && <div className="error-message">{loginError}</div>}
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    placeholder="E-mail"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
}



const CreateAccount = () => {
    const { apiUrl, loading, setLoading, hmac, setHmac } = useContext(AppContext);
    const [createAccountError, setCreateAccountError] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setphone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const navigate = useNavigate();

    const setupSession = (hmac) => {
        setHmac(hmac);
        sessionStorage.setItem('hmac_key', hmac);
    }

    const handleCreateAccount = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            if (password !== retypePassword) {
                throw new Error("Passwords do not match");
            }

            let formData = new FormData();
            formData.append('action', 'createAccount');
            formData.append('businessName', businessName);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('password', password);            

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setupSession(response.data.data);
                navigate('/');
            } else {
                const errorMessage = response.data.message;
                setCreateAccountError(errorMessage); // Set error message to state
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-account-form">
            <h2>Create Account</h2>
            {createAccountError && <div className="error-message">{createAccountError}</div>}
            <form onSubmit={handleCreateAccount}>
                <input
                    type="text"
                    placeholder="Business Name"
                    defaultValue={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="First Name"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Last Name"
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Phone Number"
                    defaultValue={phone}
                    onChange={(e) => setphone(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Retype Password"
                    defaultValue={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                />
                <button type="submit">Create Account</button>
            </form>
        </div>
    );
};




export default AccountForm;