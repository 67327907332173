import React from 'react';
import { RingLoader } from 'react-spinners'; // Import the spinner from the chosen library

const Loader = () => {
    return (
        <div className="loader">
            <RingLoader size={333} />
            <span> Astrotext AI </span>
        </div>
    );
};

export default Loader;