import React, { useState } from 'react';

const Agreement = ({ handleAgreementSubmit }) => {
    const [agreementChecked, setAgreementChecked] = useState(false);

    const handleAgreementChange = () => {
        setAgreementChecked(!agreementChecked);
    };

    const handleSubmit = () => {
        // Collect the necessary information from the agreement step
        const formData = {
        agreementChecked,
        };

        handleAgreementSubmit(formData);
    };
        
    return (
        <div>
            <h3>Legal Compliance</h3>
            <p>
                By creating an account and utilizing our website, you hereby acknowledge
                and affirm your commitment to adhering to all relevant laws and
                regulations. This includes, but is not limited to, the regulations
                established by the Federal Communications Commission (FCC) to implement
                the Telephone Consumer Protection Act (TCPA).
            </p>
            <p>
                You comprehend and agree that you bear sole responsibility for obtaining
                any necessary consents or permissions required to engage in
                communications with individuals through our website's services. This
                encompasses obtaining prior express written consent from individuals to
                receive marketing or promotional text messages or calls.
            </p>
            <p>
                It is important to recognize that our website cannot guarantee or
                enforce your compliance with the TCPA or any other applicable
                regulations. It is incumbent upon you to familiarize yourself with the
                regulations and guidelines provided by the FCC and to ensure that your
                usage of our services is fully compliant in all respects.
            </p>
            <p>
                To ensure the highest level of compliance, we highly recommend seeking
                the guidance of legal professionals who specialize in telecommunications
                regulations. These experts can provide invaluable guidance to ensure
                that your utilization of our services and your communications remain
                within the bounds of all applicable laws and regulations.
            </p>
            <p>
                Please be advised that the information and guidelines provided on our
                website are intended for informational purposes only and should not be
                construed as legal advice. For specific legal advice regarding compliance
                with the TCPA or any other relevant regulation, we strongly advise
                consulting with a qualified attorney who can offer personalized guidance
                tailored to your unique circumstances.
            </p>
            <div>
                <input type="checkbox" checked={agreementChecked} onChange={handleAgreementChange} />
                <label>I have read and agree to the terms and conditions.</label>
            </div>
            <button onClick={handleSubmit}>Next</button>
        </div>
    );
};

export default Agreement;